<template>
	<div class="ucPanel pageTname fontGreen" style="font-weight: bold;">{{department}}</div>
<div class="ucPanel bgGrayBox ctms_srh ucBtn-bg" :class="{'ctms_srhHide' : shouldshow }" >  <!-- 收起 添加 ctms_srhHide -->
		<div class="ctms_srhR_arr" @click="putaway">{{putawayname}}</div>
		<div class="ucForm ucForm-flow">
		    <div class="ucFrom-row">
				<div class="ucForm-group">
					<label class="ucForm-label">签订时间</label>
					<el-config-provider :locale="locale">
					<el-date-picker v-model="beginDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker><span style="padding: 0 15px;">-</span>
					<el-date-picker v-model="endDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
					</el-config-provider>
				</div>
		    </div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">合同编号</label>
				<input name="cno" v-model="cno" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">合同名称</label>
				<input name="cname" v-model="cname" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">合同金额/元</label>
				<input name="amount" style="width:100px" v-model="amount" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" /><span style="padding: 0 15px;">-</span>
				<input name="amount" style="width:100px" v-model="amount2" type="number" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">项目类别</label>
				<select v-model="xmlb" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="option in xmlbs" v-bind:key = "option.id" :value="option.id">
						{{option.name}}
					</option>
				</select>
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">专业</label>
				<select v-model="zy" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="option in zys" v-bind:key = "option.id" :value="option.id">
						{{option.name}}
					</option>
				</select>
			</div>
		
			
		
			<div class="ucForm-group">
				<label  class="ucForm-label">委托单位</label>
				<input name="gysmc" v-model="gysmc" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label class="ucForm-label">项目负责人</label>
				<input name="cname" v-model="xmfzr" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">工程投资</label>
				<input name="gctz" v-model="gctz" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">项目来源</label>
				<input name="xmly" v-model="xmly" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
		
			<div class="ucForm-group">
				<label  class="ucForm-label">主要内容</label>
				<input name="mark" v-model="mark" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
			</div>
			
			<div class="ucForm-group">
				<label class="ucForm-label">框架协议</label>
				<select v-model="isframe" class="ucForm-control" >
					<option  value="">请选择</option>
					<option v-for="isframe in isframes" v-bind:key="isframe.value" :value="isframe.value">
						{{isframe.isframe}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label class="ucForm-label">是否获奖</label>
				<select v-model="isaward" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="isaward in isawards" v-bind:key="isaward.value" :value="isaward.value">
						{{isaward.isaward}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label class="ucForm-label">合同状态</label>
				<select v-model="stat" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="stat in stats" v-bind:key="stat.value" :value="stat.value">
						{{stat.stat}}
					</option>
				</select>
			</div>
			<div class="ucForm-group">
				<label  class="ucForm-label">费率</label>
				<select v-model="fl" class="ucForm-control">
					<option  value="">请选择</option>
					<option v-for="fl in fls" v-bind:key="fl.value" :value="fl.value">
						{{fl.fl}}
					</option>
				</select>
			</div>
		</div>
	    <div class="ctms_srhBtn">
			<button class="ucBtn ucBtn-search ucBtn-green"  @click="fetchData()"><i class="iImg"></i>查询</button>
			<button class="ucBtn ucBtn-import ucBtn-green"  @click="handleClickExport()"><i class="iImg"></i>导出</button>
			<!-- <button class="ucBtn ucBtn-export ucBtn-green" @click="handleClickImport()"><i class="iImg"></i>导入合同列表</button> -->
		</div>
	</div>
	<div class="ucPanel bgGrayBox" style="background-color: #fff;">
		<div class="ctms_dataSta">
			<ul>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img1.png"/></i>
						<dt>合同数量 (服务) / 项</dt>
						<dd >{{serviceCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img2.png"/></i>
						<dt>合同数量 (采购) / 项</dt>
						<dd>{{purchaseCount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img3.png"/></i>
						<dt>合同金额 (服务) / 万元</dt>
						<dd>{{serviceContractAmount}}</dd>
					</dl>
				</li>
				<li>
					<dl>
						<i class="iImg"><img src="../imgs/ctms/ctms_dataSta_img4.png"/></i>
						<dt>合同金额 (采购) / 万元</dt>
						<dd>{{purchaseContractAmount}}</dd>
					</dl>
				</li>
			</ul>
			<div class="clear"></div>
		</div>
	</div>
	<el-tabs v-model="activeName" @tab-click="handleClick">
	    <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;服务合同&nbsp;&nbsp;&nbsp;&nbsp;" name="1">
			
				<div class="ucPanel ucPanel-table">
					
					<el-table :data="dataList" highlight-current-row border style="width: 100%">
						<el-table-column type="selection" width="55px" align="center"/>
						<el-table-column label="序号"  width="78px;">
							<template v-slot="scope">
								{{ (currentPage-1)*pagesize+scope.$index+1 }}
							</template>
						</el-table-column>
						<el-table-column prop="cno" label="合同编号" ></el-table-column>
						<el-table-column prop="cname" label="合同名称" ></el-table-column>
						<el-table-column prop="jsdw" label="委托单位" ></el-table-column>
						<el-table-column prop="amount" label="合同金额/元" ></el-table-column>
						<el-table-column prop="signDate" label="签订日期"></el-table-column>
						<el-table-column prop="gcjd" label="工程进度"></el-table-column>
						<el-table-column prop="famount" label="收款金额/元"></el-table-column>
						<el-table-column fixed="right" label="操作" width="180px" >
							<template v-slot="scope" >
								<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>详情</button>
							</template>
						</el-table-column>
					  </el-table>
					  <el-pagination
					  		@size-change="handleSizeChange"
					  		@current-change="handleCurrentChange"
					  		:current-page="currentPage"
					  		:page-size="pagesize" 
					  		layout="prev, pager, next"
					  		:total="total_count">   
					  </el-pagination>
				</div>
			
		</el-tab-pane>
	    <el-tab-pane label="&nbsp;&nbsp;&nbsp;&nbsp;采购合同&nbsp;&nbsp;&nbsp;&nbsp;" name="2">
			
				<div class="ucPanel ucPanel-table">
					
					<el-table :data="dataLists" highlight-current-row border style="width: 100%">
						<el-table-column type="selection" width="55px" align="center"/>
						<el-table-column label="序号"  width="78px;">
							<template v-slot="scope">
								{{ (currentPage-1)*pagesize+scope.$index+1 }}
							</template>
						</el-table-column>
						<el-table-column prop="cno" label="合同编号" ></el-table-column>
						<el-table-column prop="cname" label="合同名称"></el-table-column>
						<el-table-column prop="gysmc" label="承接单位" ></el-table-column>
						<el-table-column prop="amount" label="合同金额/元" ></el-table-column>
						<el-table-column prop="signDate" label="签订日期"></el-table-column>
						<el-table-column prop="gcjd" label="工程进度" ></el-table-column>
						<el-table-column prop="zcno" label="主体合同编号" ></el-table-column>
						<el-table-column fixed="right" label="操作" width="180px" >
							<template v-slot="scope" >
								<button class="ucBtn ucBtn-info ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>详情</button>
							</template>
						</el-table-column>
					  </el-table>
					  <el-pagination
					  		@size-change="handleSizeChange"
					  		@current-change="handleCurrentChange"
					  		:current-page="currentPage"
					  		:page-size="pagesize" 
					  		layout="prev, pager, next"
					  		:total="total_count">   
					  </el-pagination>
					
				</div>
			
		</el-tab-pane>
	</el-tabs>
	<ctms_contract_info @closecallinfo="closecallinfo"  v-if="centerDialogVisible" width="70%" ></ctms_contract_info>
</template>
<script>
	import ctms_contract_info from './ctms_contract_info.vue'
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				centerDialogVisible: false,
				dataList: [{id:'',htbh:'xxxxxxx',htmc:'xxxxxxx',cjdw:'xxxxxxx',htje:'xxxxxxx',qdrq:'2021-11-16',gcjd:'80%',zthtbh:'xxxxxxx'}],
				dataLists:[],
				infoMod: "",
				editId: null,
				beginDate:null,
				endDate:null,
				activeName: '1',
				ctype: '1',
				cno:null,
				cname:null,
				amount:null,
				amount2:null,
				gid:this.$route.params.gid,
				gysmc:null,
				xmfzr:null,
				gctz:null,
				xmly:null,
				zynr:null,
				famount:null,
				isframe:null,
				isaward:null,
				mark:null,
				zy:null,
				xmlb:null,
				fl:null,
				stat:null,
				ctypes: [
					{ ctype: '服务合同', value: '1' },
					{ ctype: '采购合同', value: '2' }
				],
				major:null,
				zys:[],
				xmlbs:[],
				departments:[],
				isframes:[
					{isframe:"是",value:'1'},
					{isframe:"否",value:'2'}
				],
				isawards:[
					{isaward:"是",value:'1'},
					{isaward:"否",value:'2'}
				],
				stats:[
					{stat: '正常', value: '1' },
					{stat: '作废', value: '2' }
				],
				fls:[
					{fl: '是', value: '1' },
					{fl: '否', value: '2' }
				],
				serviceCount:0,
			    purchaseCount:0,
				serviceContractAmount: 0,
				purchaseContractAmount: 0,
				currentPage:1, //初始页
				pagesize:10,    //    每页的数据
				total_count:0,
				shouldshow:false,
				putawayname:'收起'
	
				
			}
		},
		created() {
			this.searchDepartment();
			this.searchMajors();
			this.getType();
			this.setDate();
			this.fetchData(1);
			// this.searchAllContract();
		},
		methods: {
			handleClickEdit: function(data){
				var req = {};
				this.infoMod = "edit";
				// req.cmd = "searchDetail";
				// this.editId = data.id;
				// this.ctype = data.ctype;
				// this.cno = data.cno;
				this.editId = data.id;
				this.ctype = data.ctype;
				this.zthtId = data.zthtId;
				// this.gid = data.gid;
				console.log(this.ctype);
				this.centerDialogVisible = true;
			},
			handleClickDelete: function(data){
				this.$confirm('您确定要删除区域“'+data.name+'”吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'}).then(()=>{
						this.deleteData(data.id);
					});
			},
			handleClickAdd: function(){
				this.infoMod = "add";
				this.centerDialogVisible = true;
			},
			handleClick: function(data){
				// console.log(data)
				// this.fetchData(data.props.name);
				console.log(data)
				this.type=data.props.name;
				this.ctype=data.props.name;
				this.currentPage=1;
				this.pagesize=10;
				this.fetchData();
			},
			handleClickQuery:function(){
				this.dataList = [];
				var req = {};
				req.cmd = "searchAllContract";
				req.ctype=this.ctype;
				req.gid=this.gid;
				req.cno = this.cno;
				req.cname = this.cname;
				req.amount = null;
				req.amount = null;
				req.xmlb = this.xmlb;
				req.department = this.department;
				req.gysmc = this.gysmc;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.zynr = this.zynr;
				req.zy = this.zy;
				req.stat = this.stat;
				req.fl = this.fl
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				console.log(req.ctype)
				console.log(req.gid)
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					if(this.ctype == 1){
						this.dataList = res.datas.result.contract;
					}else{
						this.dataLists = res.datas.result.contract;
					}
					this.total_count=res.datas.result.total_count;
				}, null, this);	
			},
			fetchData: function(){
				if(this.gid==null||typeof this.gid=='undefined'){
					this.$router.push({name:'ctms_c_bm',params:[]});
				}
				this.dataList = [];
				var req = {};
				req.cmd = "searchAllContract";
				req.cno = this.cno;
				req.gid = this.gid;
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.ctype = this.ctype;
				req.cname = this.cname;
				req.xmlb = this.xmlb;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.zy = this.zy;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.mark = this.mark;
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.stat = this.stat;
				req.fl = this.fl;
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				console.log(req.ctype)
				console.log(req.gid)
				
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res);
					if(req.ctype == 1){
						this.dataList = res.datas.result.contract;
						console.log(this.dataList)
					}else{
						this.dataLists = res.datas.result.contract;
						console.log(this.dataList)
					}
					this.total_count=res.datas.result.total_count;
				}, null, this);	
			},
			deleteData: function(id){
				this.dataList = [];
				var req = {};
				req.cmd = "mng_conf_area_del";
				req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					this.fetchData();
				}, null, this);	
			},
			closecallinfo: function() {
				this.centerDialogVisible = false;
				this.fetchData(1);
			},
			searchMajors: function(){
				this.zys = [];
				var req = {};
				req.cmd = "searchMajors";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.zys = res.datas.result.majors;
					this.zys.zy = res.datas.result.majors.id;
					console.log(this.zy);
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=2;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			getType:function(){
				this.xmlbs = [];
				var req = {};
				req.cmd = "getType";
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.majors);
					this.xmlbs = res.datas.result.majors;
					console.log(this.xmlbs);
				}, null, this);	
			},
			handleClickExport:function(){
				var req = {};
				req.cno = this.cno;
				req.gid = this.gid;
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.ctype = this.ctype;
				req.cname = this.cname;
				req.xmlb = this.xmlb;
				req.amount = this.amount;
				req.amount2 = this.amount2;
				req.zy = this.zy;
				req.xmfzr = this.xmfzr;
				req.gctz = this.gctz;
				req.xmly = this.xmly;
				req.mark = this.mark;
				req.isframe = this.isframe;
				req.isaward = this.isaward;
				req.stat = this.stat;
				req.fl = this.fl;
				req.cmd = "exportContractExcel";
				this.$sknet.download(this.$skconf("iot.surl"), req)
			},
			handleSizeChange: function (size) {
				this.pagesize = size;
				console.log(this.pagesize)  //每页下拉显示数据
			},
			handleCurrentChange: function(currentPage){
				this.currentPage = currentPage;
				this.fetchData();
				console.log(this.currentPage)  //点击第几页
			},
			putaway:function() {
				if(this.putawayname=='收起'){
					this.shouldshow=true;
					this.putawayname='打开';
				}else{
					this.shouldshow=false;
					this.putawayname='收起';
				}
			},
			setDate:function() {
				const y = new Date().getFullYear();
				const f = y + '-01-01';
				const l = y + '-12-31';
				this.beginDate = f;
				this.endDate=l;
			}
		},
		watch: {
		},
		components: {
			ctms_contract_info
		},
		mounted: function() {
			// this.myIndex = this.$route.params.msgKey
			console.log(this.$route.params)
			this.department=this.$route.params.department;
			this.gid = this.$route.params.id;
			this.serviceCount = this.$route.params.serviceCount;
			this.purchaseCount = this.$route.params.purchaseCount;
			this.serviceContractAmount = this.$route.params.serviceContractAmount;
			this.purchaseContractAmount = this.$route.params.purchaseContractAmount;
			//this.fetchData(1);
		}
	}
</script>